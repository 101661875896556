<div style="position: relative;">
    <section id="horsepower-locator-tile">
        <div class="hero-container fade-in">
          <div class="horsepower-logo">
              <img src="/assets/images/hpl-logo-white-base.webp" alt="Dodge Horsepower Locator - Logo" class="img-responsive" width="768" height="413">


          </div>
          <div class="horsepower-content">
              <p id="dont-say-warning-text" class="text-center text-white text-uppercase">
                    <span class="combo">
                      CHARGER DAYTONA 2-DOOR
                    </span>
                  <span>+</span>
                  <span>DURANGO SPECIAL EDITIONS</span>
              </p>
              <a routerLink="/horsepower-locator" id="hpl-breakout-link" class="btn btn-primary" (click)="sendGTMEvent()">Find your ride</a>
          </div>
          <div class="incentives-copy text-center">
            <p>GIVING AMERICANS FREEDOM OF CHOICE</p>
            <p>RECEIVE EMPLOYEE PRICING ON SELECT 2024 OR 2025 DODGE MODELS <span class="v-adjust" (click)="openTooltipModal(1)">1</span></p>
            <p>OR</p>
            <p>CHOOSE <a href="https://www.dodge.com/vehicle-selector.incentives.html?app=incentives" target="_blank" style="color:white;">AVAILABLE ATTRACTIVE OFFERS</a></p>
            <p>THE CHOICE IS YOURS.</p>
          </div>
        </div>
    </section>
</div>

<ng-template #tooltipModal>
    <div class="modal-container modal-container-lg">
        <div class="modal-header">
            <button class="modal-close" (click)="tooltipModalRef.hide()"><i class="fa fa-close"><span class="sr-only">close</span></i></button>
        </div>
        <div class="modal-body">
            <p class="fs-legal" *ngIf="activeTooltip === 1">
              Offer applies to 2024 and 2025 Daytona and Durango models and 2024 Hornet models. Offer is available on the purchase or lease of a new vehicle. Customer responsible for $200 administration fee. Not compatible with any other consumer incentive offers. Contact dealer for details. Take delivery by 04/30/2025.
            </p>
            <p class="fs-legal" *ngIf="activeTooltip === 2">
                Contact dealer for details. Must take delivery by 04/30/2025.
            </p>
        </div>
    </div>
</ng-template>
